import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Box, Typography, Grid, Container } from "@mui/material";
import { StaticImage, getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import { Section } from "../Section";
import { Blockquote } from "../Blockquote";

import { StyledHome } from "./Home.styled";

import ImgIconDiagnosis from "../../images/icon-diagnosis.svg";
import ImgIconSplint from "../../images/icon-splint.svg";
import ImgIconTherapy from "../../images/icon-therapy.svg";

const HomeContent = ({ children }) => {
  return (
    <StyledHome>
      <Section>
        <Container maxWidth="lg">
          <Box p={4}>
            <Typography variant="h4" color="secondary" align="center" mb={2}>
              Welcome to the offices of The Joyner Hand, PA.
            </Typography>
            <Typography paragraph align="center">
              The Joyner Hand provides complete surgical and non surgical hand
              care with the highest quality of service in a timely manner to
              every patient served. Dr. Michael S. Joyner takes pride in being
              your Hand Surgeon. Every person is treated like "family." With
              extensive training in the care of various joint and sports related
              injuries, our priority is to help you make a quick recovery and
              return to an active lifestyle. Our individualized approach to your
              treatment options enables us to help you make the best decision
              for your particular situation. We will not proceed with any
              treatment without first explaining it fully and answering all of
              your questions.
            </Typography>
            <Box>
              <Box display="flex" justifyContent="center">
                <StaticImage
                  src="../../images/operating.jpg"
                  alt="operating room"
                />
              </Box>
              <Blockquote>
                "Human - for he is an intelligent animal...in place of any and
                every defensive weapon, she gave hands, instruments necessary
                for every art and useful in peace no less than war" - Galen
              </Blockquote>
            </Box>
          </Box>
        </Container>
      </Section>
      <Section secondary>
        <Container maxWidth="lg">
          <Box py={4}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4} p={4}>
                <Box display="flex" justifyContent="center" mb={4}>
                  <Box
                    component="img"
                    src={ImgIconDiagnosis}
                    alt="magnifying glass over hand"
                    sx={{ maxHeight: `80px` }}
                  />
                </Box>
                <Typography
                  variant="h5"
                  color="common.white"
                  align="center"
                  mb={2}
                >
                  <strong>Experts in the diagnosis</strong>
                </Typography>
                <Typography paragraph align="center" color="common.white">
                  Hand surgeons are experts in the diagnosis and treatment of
                  problems of the hand, wrist, and forearm. Hand surgery is a
                  subspecialty of orthopedics, plastics, and general surgery
                  requiring an additional year or more of training after
                  completing five years of surgical residency. A qualified hand
                  surgeon is an expert in the complexities of hand anatomy and
                  microvascular surgery, which enables the surgeon to operate on
                  the small bones and delicate nerves, vessels, and other
                  structures in the hand, wrist, and forearm.
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Box display="flex" justifyContent="center" mb={4}>
                  <Box
                    component="img"
                    src={ImgIconSplint}
                    alt="hand in splint"
                    sx={{ maxHeight: `80px` }}
                  />
                </Box>
                <Typography
                  variant="h5"
                  color="common.white"
                  align="center"
                  mb={2}
                >
                  <strong>Non Surgical Treatment</strong>
                </Typography>
                <Typography color="common.white" align="center">
                  Although you may be referred to see a hand surgeon by your
                  doctor or the emergency department, that does not necessarily
                  mean you need surgery. Many conditions are primarily treated
                  nonsurgically with medications, injections, splints, or
                  therapy. Your hand surgeon will diagnose your problem and then
                  recommend the best course of treatment. Common problems
                  treated by a hand surgeon include carpal tunnel syndrome and
                  hand numbness, finger lacerations with tendon or nerve injury,
                  hand and wrist arthritis, and hand fractures.
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box display="flex" justifyContent="center" mb={4}>
                  <Box
                    component="img"
                    src={ImgIconTherapy}
                    alt="hand with cross"
                    sx={{ maxHeight: `80px` }}
                  />
                </Box>
                <Typography
                  variant="h5"
                  color="common.white"
                  align="center"
                  mb={2}
                >
                  <strong>Seek Treatment</strong>
                </Typography>
                <Typography color="common.white" align="center">
                  If you have an injury or problem with your hand, wrist, or
                  forearm that is causing pain or is affecting your hand, you
                  may want to seek the treatment of a hand surgeon
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Section>
    </StyledHome>
  );
};

const Home = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "hero-home.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );
  const pluginImage = getImage(placeholderImage);

  return (
    <>
      <BgImage image={pluginImage} style={{ minHeight: 375 }}>
        <Box
          sx={{
            height: 375,
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `center`,
            alignItems: `middle`,
          }}
        >
          <Box>
            <Typography variant="h3" align="center" color="common.white">
              <strong> Complete Hand Surgery</strong>
            </Typography>
            <Typography variant="h4" align="center" color="common.white">
              Surgical and Non-Surgical Hand Care
            </Typography>
          </Box>
        </Box>
      </BgImage>
      <HomeContent />
    </>
  );
};

export default Home;
